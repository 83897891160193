import types from '../../types/authorization.js';
import dashboardType from '../../types/dashboard.js';
import SessionCreator from '../../../enums/Session.js';
import AuthorizationAPI from '../../../services/AuthorizationAPI.js';
import UserAPI from '../../../services/UserAPI.js';

const SESSION_STATUS = SessionCreator();
const a = types.actions;
const m = types.mutations;
const md = dashboardType.mutations;

export default {
  [a.INIT_USER_SESSION]({ commit }, { user }) {
    commit(m.SET_SESSION_USER, user);
    commit(m.SET_SESSION_USER_ID, user.id);
    commit(m.SET_FORCE_PASSWORD_UPDATE, user.force_password_change);
    commit(m.SET_OTP_ENABLED, !!user.google2fa_confirmed);
    commit(m.SET_SESSION_STATUS, SESSION_STATUS.LOGGED_IN);

    if (user.force_password_change === true) {
      commit(m.SET_SESSION_STATUS, SESSION_STATUS.PASSWORD_CHANGE);
    }
  },

  /**
   * @name a LOGIN_USER
   * @summary Logs the user in with the credentials supplied.
   * @description This will update the `session_status` and the `session` details will be
   * populated if successful.
   * If it fails then the `session_errors` will be populated.
   * @method
   * @param {Object} context
   * @param {String} email The email address of the user (part of the payload object)
   * @param {String} password The password (part of the payload object)
   * @param {boolean} rememberMe Keep the user logged in
   */
  async [a.LOGIN_USER]({ commit, state, dispatch }, { username, password, rememberMe }) {
    try {
      commit(m.SET_SESSION_STATUS, SESSION_STATUS.LOGGING_IN);
      commit(m.SET_SESSION_ERRORS, []);

      const user = await AuthorizationAPI.login(username, password, rememberMe);

      dispatch(a.INIT_USER_SESSION, { user });
    } catch (error) {
      commit(m.SET_SESSION_STATUS, SESSION_STATUS.LOGIN_FAILED);
      const message =
        error?.response?.data?.message ?? error?.message ?? 'An error occurred while logging in. Please try again';

      commit(m.SET_SESSION_ERRORS, Array.isArray(message) ? message : [message]);
    }

    return state.session_status;
  },

  async [a.LOGIN_SSO]({ commit, state, dispatch }, { code, state: ssoState }) {
    try {
      commit(m.SET_SESSION_STATUS, SESSION_STATUS.LOGGING_IN);
      commit(m.SET_SESSION_ERRORS, []);

      const user = await AuthorizationAPI.loginSSO(code, ssoState);
      dispatch(a.INIT_USER_SESSION, { user });
    } catch (error) {
      commit(m.SET_SESSION_STATUS, SESSION_STATUS.LOGIN_FAILED);
      const message =
        error?.response?.data?.message ?? error?.message ?? 'An error occurred while logging in. Please try again';

      commit(m.SET_SESSION_ERRORS, Array.isArray(message) ? message : [message]);
    }

    return state.session_status;
  },

  async [a.REFRESH_USER]({ commit, dispatch }) {
    try {
      const user = await UserAPI.getMe();
      dispatch(a.INIT_USER_SESSION, { user });
    } catch (error) {
      commit(m.SET_SESSION_STATUS, SESSION_STATUS.SESSION_EXPIRED);
      const message =
        error?.response?.data?.message ??
        error?.message ??
        'Your session has expired. Please log in again';

      commit(m.SET_SESSION_ERRORS, Array.isArray(message) ? message : [message]);
    }
  },

  async [a.IMPERSONATE_USER]({ commit }, { userId }) {
    await AuthorizationAPI.impersonateUser(userId);
    const user = await UserAPI.getMe();
    commit(m.SET_SESSION_USER_ID, user.id);
    commit(m.SET_SESSION_USER, user);
    commit(m.SET_OTP_ENABLED, !!user.google2fa_confirmed);
  },

  /**
   * @name a LOGOUT_USER
   * @summary Log the current user out of the system.
   * @desc This will update the `session_status` and empty the `session` details.
   * If it fails then the `session_errors` will be populated.
   * @method
   * @param {Object} context
   */
  async [a.LOGOUT_USER]({ dispatch, commit }) {
    commit(m.SET_SESSION_STATUS, SESSION_STATUS.LOGGING_OUT);
    try {
      await AuthorizationAPI.logout();
      // eslint-disable-next-line no-empty
    } catch (error) {}

    dispatch(a.KILL_SESSION);

    commit(m.SET_SESSION_STATUS, SESSION_STATUS.LOGGED_OUT);
  },

  [a.KILL_SESSION]({ commit }) {
    commit(m.SET_SESSION, null);
    commit(m.SET_SESSION_USER_ID, null);
    commit(m.SET_SESSION_USER, null);
    commit(md.SET_ACTIVE_DASHBOARD, null);
    commit(m.SET_SESSION_ERRORS, []);
  },

  async [a.CONFIRM_OTP]({ commit }, { otp }) {
    const response = await AuthorizationAPI.confirmOtp(otp);

    commit(m.SET_OTP_ENABLED, true);

    return response.backup_codes;
  },

  async [a.DISABLE_OTP]({ commit }, { password }) {
    await AuthorizationAPI.disableOtp(password);

    commit(m.SET_OTP_ENABLED, false);

    return true;
  },
};
