import axios from 'axios';

/**
 * Interface to the Authorization API endpoints
 * @class
 * @exports src/services/AuthorizationAPI
 */
class AuthorizationAPI {
  /**
   * Attempts to create a session with the given credentials
   * @method
   * @static
   * @param  {string}  username    User's username
   * @param  {string}  password User's password
   * @param  {boolean}  rememberMe Keep the user logged in
   * @return {Promise}          Promise that resolves to the {@link Session} details.
   */
  static async login(username, password, rememberMe) {
    const response = await axios({
      method: 'POST',
      url: 'login',
      responseType: 'json',
      data: {
        username,
        password,
        ...(rememberMe ? { remember: true } : {}),
      },
    });

    return response.data.data;
  }

  static async loginSSO(code, state) {
    const response = await axios({
      method: 'GET',
      url: `sso/callback?code=${code}&state=${state}`,
      responseType: 'json',
    });
    return response.data.data;
  }

  /**
   * Logs the current user out.
   * @method
   * @static
   * @return {Promise}          Promise that resolves to the server's response
   */
  static async logout() {
    try {
      await axios({
        method: 'POST',
        url: 'logout',
        responseType: 'json',
      });
      // eslint-disable-next-line no-empty
    } catch (error) {} // always log user out locally, regardless of response

    return true;
  }

  /**
   * Changes a user's password.
   * @method
   * @static
   * @param {string} userId The user's ID.
   * @param {string} oldPassword The user's existing password.
   * @param {string} newPassword The password to set for the user.
   * @return {Promise}
   */
  static async changePassword(userId, newPassword, oldPassword) {
    if (oldPassword) {
      return axios.patch(`user/${userId}`, {
        old_password: oldPassword,
        password: newPassword,
      });
    }

    return axios.patch(`user/${userId}`, {
      password: newPassword,
    });
  }

  /**
   * Enables impersonation of the supplied user
   * @method
   * @static
   * @param  {?string}  userId The user to impersonate
   * @return {Promise}       Promise that resolves when impersonation is enabled
   */
  static async impersonateUser(userId) {
    const response = await axios({
      method: 'POST',
      url: 'impersonate',
      responseType: 'json',
      data: {
        impersonated_user_id: userId,
      },
    });

    return response.data;
  }

  static async getSharedSecretSetupQRCode() {
    const response = await axios({
      method: 'GET',
      url: '/auth/shared_secret/setup/qr_code',
      responseType: 'json',
    });

    return response.data;
  }

  /**
   * Sends an OTP to verify correct setup of 2fa.
   * @method
   * @static
   * @async
   * @param  {string}  otp    The otp to verify
   * @return {Promise}
   */
  static async confirmOtp(otp) {
    const response = await axios({
      method: 'POST',
      url: '/auth/shared_secret/setup/confirmation',
      responseType: 'json',
      data: {
        otp,
      },
    });

    return response.data;
  }

  /**
   * Sends an OTP to second-factor login for user
   * @method
   * @static
   * @async
   * @param  {string}  otp    The otp to authenticate with
   * @return {Promise}
   */
  static async authenticateWithOtp(otp) {
    const response = await axios({
      method: 'POST',
      url: '/auth/otp/authenticate',
      responseType: 'json',
      data: {
        otp,
      },
    });

    return response.data;
  }

  static async disableOtp(password) {
    await axios({
      method: 'POST',
      url: '/auth/shared_secret/deactivate',
      responseType: 'json',
      data: {
        password,
      },
    });

    return true;
  }
}

export default AuthorizationAPI;
